import sortArrows from "../../../images/sort-arrows.png";
import selectArrow from "../../../images/select-arrow.png";
import gridIcon from "../../../images/grid-icon.png";
import listIcon from "../../../images/list-icon.png";

export default theme => ({
    subCatNav: {
      position: 'relative',
      borderBottom: '1px solid #BAC2CE',
      marginBottom: 30,
      [theme.breakpoints.up('md')]: {
          marginTop: 50,
          borderBottom: 'none',
          padding: 0,
          backgroundColor: theme.palette.primary.main + '1A',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
      },
      '& .count': {
          fontSize: 10,
          fontFamily: theme.bodyFont,
          color: theme.palette.primary.main,
          letterSpacing: '0.152em',
          lineHeight: 1.6,
          fontWeight: 500,
          textTransform: 'uppercase',
          [theme.breakpoints.up('md')]: {
            padding: '12px 20px',
          },
      },
      '& button.trigger': {
          padding: 0,
          border: 'none',
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          fontSize: 18,
          fontWeight: 400,
          lineHeight: 1,
          fontFamily: theme.bodyFont,
          color: theme.palette.primary.main,
          float: 'right',
          outline: 'none',
          [theme.breakpoints.up('md')]: {
              display: 'none',
          },
          '&::before': {
            content: '""',
            width: 22,
            height: 13,
            backgroundImage: 'url(' + sortArrows + ')',
            backgroundPosition: 'center',
            display: 'inline-block',
            marginRight: 5,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }
      },
      '& .sort-wrap': {
          backgroundColor: theme.palette.primary.main + '1A',
          marginTop: 12,
          overflow: 'hidden',
          height: 0,
          opacity: 0,
          transition: 'opacity .3s ease-in-out',
          '&[open]': {
            height: 'auto',
            opacity: 1,
          },
          [theme.breakpoints.up('md')]: {
              height: 'auto !important',
              opacity: '1 !important',
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 0,
          },
          '& .select-row': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px 12px 0',
              alignItems: 'center',
              [theme.breakpoints.up('md')]: {
                padding: 12,
              },
              '& span': {
                fontSize: 10,
                fontFamily: theme.bodyFont,
                color: theme.palette.primary.main,
                letterSpacing: '0.152em',
                lineHeight: 1.6,
                fontWeight: 500,
                textTransform: 'uppercase',
                [theme.breakpoints.up('md')]: {
                  paddingRight: 6,
                },
              },
              '& select': {
                borderRadius: 20,
                color: theme.palette.primary.main + '4D',
                border: 'none',
                fontSize:14,
                letterSpacing: '0.008em',
                padding: '10px 45px 10px 20px',
                lineHeight: 1.1,
                minWidth: 150,
                fontFamily: theme.bodyFont,
                backgroundImage: 'url(' + selectArrow + ')',
                backgroundPosition: 'center right 15px',
                backgroundSize: '13px 11px',
                backgroundRepeat: 'no-repeat',
                appearance: 'none',
                outline: 'none',
                '-webkit-appearance': 'none',
                '-moz-appearance': 'none',
                '-ms-appearance': 'none',
                [theme.breakpoints.up('md')]: {
                  minWidth: 0,
                },
              },
              '&.show-count': {
                  paddingBottom: 12,
              }
          },
          '& .show-style': {
              display: 'none',
              [theme.breakpoints.up('md')]: {
                  display: 'block',
              },
              '& button': {
                width: 60,
                height: 60,
                border: 'none',
                outline: 'none',
                backgroundColor: 'transparent',
                fontSize: 0,
                padding: 0,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                opacity: .3,
                transition: 'opacity .3s ease-in-out, background-color .3s ease-in-out',
                '&.grid-trigger': {
                    backgroundImage: 'url(' + gridIcon + ')',
                },
                '&.list-trigger': {
                    backgroundImage: 'url(' + listIcon + ')',
                },
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.main + '1A',
                    opacity: 1,
                }
              }
          }
      }
    }
 });