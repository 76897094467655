import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import styles from './SubCategoryNav.styles';

import { setLayout, setSort, itemsToShow, setPage } from '../../../app/actions';

const useStyles = makeStyles(theme => styles(theme));

const mapDispatchToProps = (dispatch) => ({
  setLayoutToGrid: () => dispatch(setLayout('grid')),
  setLayoutToList: () => dispatch(setLayout('list')),
  setSortType: sort => dispatch(setSort(sort)),
  setItemsToShow: number => dispatch(itemsToShow(number)),
  setPage: page => dispatch(setPage(page)),
});

const mapStateToProps = (state) => ({
  itemsToShow: state.ShopSettings.itemsToShow,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function SubcategoryNav({ products, setLayoutToGrid, setLayoutToList, setSortType, itemsToShow, setItemsToShow, setPage, total = 0 }) {
  const [open, setOpen] = React.useState(false);
  
  const showMore = () => {
      setOpen(!open);
  }

  function itemsToShowHandler(value) {
    setItemsToShow(value);
    setPage(1);
  }

  const classes = useStyles();

  return <div className={classes.subCatNav}>
    <span className="count">{ products.length } { products.length === 1 ? 'product' : 'products' }</span>
    <button className="trigger" onClick={showMore} onKeyDown={showMore} open={open}>Sort</button>
    <div className="sort-wrap" open={open}>
      <div className="select-row show-count">
        <span>Show</span>
        <select defaultValue={itemsToShow} onChange={(e) => itemsToShowHandler(e.target.value)}>
          {Number(total) <= 300 && <option value="12">12</option>}
          {Number(total) <= 500 && <option value="24">24</option>}
          <option value="48">48</option>
          <option valiue="all">All</option>
        </select>
      </div>
      {/* <div className="show-style">
        <button onClick={() => setLayoutToGrid()} className="grid-trigger">Grid</button>
        <button onClick={() => setLayoutToList()} className="list-trigger">List</button>
      </div> */}
    </div>
  </div>;
};

export default connector(SubcategoryNav);