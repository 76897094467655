import React, { useState } from 'react';
import { navigate } from "gatsby";
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import styles from './SideNav.styles';
import ReactHtmlParser from "react-html-parser";
import { transformLink } from '../../../helpers/';

import { setFilter } from '../../../app/actions';

import arrow from "../../../images/button-arrow.png";

const useStyles = makeStyles(theme => styles(theme));

const mapStateToProps = (state) => ({
  shop: state.ShopSettings,
  filters: state.ShopSettings.filter
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (prevFilters, filter) => dispatch(setFilter(prevFilters, filter))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function SideNav({ name, id, description, uri, parent, subcategories, products, setFilter, shop, filters, siblings, total }) {
  const [open, setOpen] = React.useState(false);
  const [catOpen, setCatOpen] = React.useState(false);
  const [query, setQuery] = useState('');

  const classes = useStyles();
  
  const showMore = () => {
      setOpen(!open);
  }
  
  const showCats = () => {
      setCatOpen(!catOpen);
  }

  function handleSubmit(e) {
    if (e) e.preventDefault(e);
    if (!query) return;

    navigate('/product-search', { state: { query } });
  }

  function filterHandler(e) {
    setFilter(shop.filter, { [e.target.value]: e.target.checked });
  }

  const isCategory = !parent && subcategories && !!subcategories.length;
  const isSubcategory = parent && !parent.node.wpParent;
  const isSubSubCategory = parent && !subcategories.length;

  if (isCategory) {
    return <aside className={classes.sideNav}>
      <h2>{ name }</h2>
      <p open={open}>
        { ReactHtmlParser(description, {transform : transformLink}) }
      </p>
      <button className="more" onClick={showMore} onKeyDown={showMore} open={open}>Read</button>
      <div className="filter-block">
        <button className={`expand-title ${!isCategory ? 'border' : ''}`} onClick={showCats} onKeyDown={showCats} open={catOpen}>Categories</button>
        <div className="filter-block-expand" open={catOpen}>
          {isCategory && <div className="current">
            { name }
          </div>}

          <div className="categories">
            <ul>
              {subcategories.map((subcategory, index) => {
                return <li key={index}>
                  <Link to={subcategory.uri} >
                    <span className={`${id === subcategory.id ? 'active' : ''}`}>{subcategory.name}</span>
                    <span className="total">{subcategory.products.length}</span>
                  </Link>
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  }

  if (isSubcategory) {
    const fallbackLength = parent.node.wpChildren.nodes.find(node => node.id === id).products.nodes.length;
    return <aside className={classes.sideNav}>
      <h2>{ name }</h2>
      <p open={open}>
        { ReactHtmlParser(description, {transform : transformLink}) }
      </p>
      <button className="more" onClick={showMore} onKeyDown={showMore}>Read</button>
      <div className="filter-block">
        <button className="expand-title" onClick={showCats} onKeyDown={showCats} open={catOpen}>Categories</button>
        <div className="filter-block-expand" open={catOpen}>

            <div className="current">
            { parent && <span>See all <Link to={parent.node.uri}>{ parent.node.name } <img src={arrow} alt={parent.node.name} /></Link></span>}
            </div>

            <div className="categories">
              <ul>
                {subcategories.map((subsubcategory, zindex) => (
                  <li key={zindex}>
                    <Link to={subsubcategory.uri}>
                        {subsubcategory.name}
                      <span className="total">{subsubcategory.products.length}</span>
                    </Link>
                  </li>
                ))}
                {!subcategories.length && <li><Link to={uri}><span className="active">{name}</span><span className="total">{fallbackLength}</span></Link></li>}
              </ul>
            </div>
          </div>
        </div>
      <div>

      {/* <div>
        <label for="kitchen">Kitchen</label>
        <input type="checkbox" name="kitchen" value="kitchen" checked={filters.kitchen} onChange={filterHandler} />
        <label for="bathroom">Bathroom</label>
        <input type="checkbox" name="bathroom" value="bathroom" checked={filters.bathroom} onChange={filterHandler} />
      </div> */}

      <form onSubmit={e => handleSubmit(e)} className="product-search">
        <label htmlFor="search">Search our products</label>
        <input type="text" name="search" placeholder="Name, Product, Brand, Search" value={query} minLength={2} required onChange={e => setQuery(e.target.value)} />
        <input type="submit" value="search" />
      </form>
      </div>
    </aside>
  }

  if (isSubSubCategory) {
    return <aside className={classes.sideNav}>
    <h2>{ name }</h2>
    <p open={open}>
      { ReactHtmlParser(description, {transform : transformLink}) }
    </p>
    <button className="more" onClick={showMore} onKeyDown={showMore}>Read</button>
    <div className="filter-block">
      <button className="expand-title" onClick={showCats} onKeyDown={showCats} open={catOpen}>Categories</button>
      <div className="filter-block-expand" open={catOpen}>

          <div className="current">
          { parent && <span>See all <Link to={parent.node.uri}>{ parent.node.name } <img src={arrow} alt="" /></Link></span>}
          </div>

          <div className="categories">
            <ul>
              {siblings.map((subcategory, index) => {
                return <li key={index}>
                  <Link to={subcategory.uri}>
                    <span className={`${id === subcategory.id ? 'active' : ''}`}>{subcategory.name}</span>
                    <span className="total">{subcategory.products.length}</span>
                  </Link>
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
    <div>

    {/* <div>
      <label for="kitchen">Kitchen</label>
      <input type="checkbox" name="kitchen" value="kitchen" checked={filters.kitchen} onChange={filterHandler} />
      <label for="bathroom">Bathroom</label>
      <input type="checkbox" name="bathroom" value="bathroom" checked={filters.bathroom} onChange={filterHandler} />
    </div> */}

    <form onSubmit={e => handleSubmit(e)} className="product-search">
      <label for="search">Search our products</label>
      <input type="text" name="search" placeholder="Name, Product, Brand, Search" value={query} minLength={2} required onChange={e => setQuery(e.target.value)} />
      <input type="submit" value="search" />
    </form>
    </div>
  </aside>
  }

  return <aside className={classes.sideNav}>
      <h2>{ name }</h2>
      <p open={open}>
        { ReactHtmlParser(description, {transform : transformLink}) }
      </p>
      <button className="more" onClick={showMore} onKeyDown={showMore}>Read</button>
      <div className="filter-block">
        <button className="expand-title" onClick={showCats} onKeyDown={showCats} open={catOpen}>Categories</button>
        <div className="filter-block-expand" open={catOpen}>

            <div className="current">
            { parent && <span>See all <Link to={parent.node.uri}>{ parent.node.name } <img src={arrow} alt="" /></Link></span>}
            </div>

            <div className="categories">
              <ul>
                <li>
                  <Link to={uri} >
                    <span className="active">{name}</span>
                    <span className="total">{total ? total : products.length}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      <div>

      <form onSubmit={e => handleSubmit(e)} className="product-search">
        <label htmlFor="search">Search our products</label>
        <input type="text" name="search" placeholder="Name, Product, Brand, Search" value={query} minLength={2} required onChange={e => setQuery(e.target.value)} />
        <input type="submit" value="search" />
      </form>
      </div>
    </aside>
}

export default connector(SideNav);