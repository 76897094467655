import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styles from './SideNav.styles';
import { itemsToShow, setPage } from '../../../app/actions';

const useStyles = makeStyles(theme => styles(theme));

const mapStateToProps = (state) => ({
  itemsToShow: state.ShopSettings.itemsToShow,
  page: state.ShopSettings.page
});

const mapDispatchToProps = (dispatch) => ({
  setPage: page => dispatch(setPage(page)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function Pagination({ setPage, products, itemsToShow, page }) {
  const classes = useStyles();
  if (itemsToShow === 'All') return null;

  const totalPages = [...Array(Math.ceil(products.length / Number(itemsToShow)))].map(_ => products.splice(0, Number(itemsToShow)));

  if (totalPages.length < 2) return null;

  function paginationHandler(index) {
    setPage(index + 1);
    setTimeout(() => scrollTo('.breadcrumb'), 100);
  }

  return <ul className={classes.pagination}>
    {totalPages.map((p, index) => {
      return <li><a className={`${index + 1 === page ? 'active' : ''}`} onClick={e => paginationHandler(index)}>{ index + 1 }</a></li>
    })}
  </ul>;
}


export default connector(Pagination);