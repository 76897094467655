import arrow from "../../../images/button-arrow.png";
import icon from "../../../images/search.png";
import currentPage from '../../../images/pagination-dot.png';

export default theme => ({
   sideNav: {
     position: 'relative',
     paddingTop: 40,
     [theme.breakpoints.up('md')]: {
       paddingTop: 50,
     },
     '& h2': {
       fontSize: 28,
       lineHeight: 1.14,
       marginTop: 0,
       marginBottom: 16,
       fontWeight: 'bold',
       [theme.breakpoints.up('md')]: {
         marginBottom: 24,
         fontSize: 32,
         letterSpacing: '0.08em',
         lineHeight: 1.5
       }
     },
     '& > p': {
      fontSize: 16,
      letterSpacing: '0.032em',
      lineHeight: 1.5,
      height: 93,
      overflow: 'hidden',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 48,
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 13%,rgba(255,255,255,1) 100%)',
        zIndex: 1,
        transition: 'opacity .3s ease-in-out',
      },
      '&[open]': {
        height: 'auto',
        '&::after': {
          opacity: 0,
        }
      }
     },
     '& .more': {
       border: 'none',
       padding: 0,
       backgroundColor: 'transparent',
       outline: 'none',
       fontFamily: theme.bodyFont,
       fontWeight: 'bold',
       letterSpacing: '0.025em',
       fontSize: 16,
       lineHeight: 1.1,
       textDecoration: 'underline',
       color: theme.palette.secondary.main,
       transition: 'color .3s ease-in-out',
       '&::after': {
         content: '" more"',
       },
       '&:hover, &:focus': {
         color: theme.palette.primary.main,
       },
       '&[open]': {
         '&::after': {
           content: '" less"',
         }
       }
     },
     '& .filter-block': {
       backgroundColor: theme.palette.secondary.main + '1A',
       borderRadius: 6,
       marginTop: 60,
       marginBottom: 32,
       '& .expand-title': {
         border: 'none',
         fontFamily: theme.bodyFont,
         fontSize: 18,
         textDecoration: 'underline',
         padding: 0,
         display: 'block',
         outline: 'none',
         width: '100%',
         color: theme.palette.primary.main,
         backgroundColor: 'transparent',
         textAlign: 'left',
         padding: '24px 20px',
         fontWeight: 500,
         position: 'relative',
         '&.border': {
          borderBottom: '1px solid #BAC2CE',
         },
         '&::after': {
           width: 16,
           height: 20,
           backgroundImage: 'url(' + arrow + ')',
           content: '""',
           display: 'block',
           position: 'absolute',
           right: 20,
           top: '50%',
           transform: 'translateY(-50%) rotate(90deg)',
           transition: 'transform .3s ease-in-out',
         },
         '&[open]': {
           '&::after': {
            transform: 'translateY(-50%) rotate(-90deg)',
           }
         },
         [theme.breakpoints.up('md')]: {
           padding: 30,
           textDecoration: 'none',
           '&::after': {
             display: 'none',
           }
         }
       },
       '& .filter-block-expand': {
         height: 0,
         overflow: 'hidden',
         transition: 'opacity .3s ease-in-out',
         opacity: 0,
         [theme.breakpoints.up('md')]: {
          height: 'auto !important',
          opacity: '1 !important',
         },
         '&[open]': {
          opacity: 1,
          height: 'auto',
         },
         '& .current': {
           padding: '0 40px 12px 20px',
           borderBottom: '1px solid #BAC2CE',
           color: theme.palette.secondary.main,
           fontSize: 14,
           letterSpacing: '0.025em',
           lineHeight: 1.2,
           position: 'relative',
           [theme.breakpoints.up('md')]: {
             padding: '0 50px 24px 30px',
           },
           '& a': {
             color: theme.palette.secondary.main,
             textDecoration: 'none',
             fontWeight: 500,
             '& img': {
               width: 12,
               marginLeft: 5,
               display: 'block',
               verticalAlign: 'top',
               position: 'absolute',
               top: 'calc(50% - 15px)',
               right: 20,
               [theme.breakpoints.up('md')]: {
                 right: 30,
                 top: 'calc(50% - 20px)',
               }
             }
           }
         },
         '& .categories': {
           padding: '12px 20px',
           [theme.breakpoints.up('md')]: {
             padding: '30px 30px 14px',
           },
           '& ul': {
             display: 'block',
             margin: 0,
             padding: 0,
             '& li': {
               paddingBottom: 12,
               display: 'block',
               [theme.breakpoints.up('md')]: {
                 paddingBottom: 16,
               },
               '& a': {
                paddingBottom: 12,
                color: theme.palette.secondary.main,
                fontSize: 14,
                letterSpacing: '0.025em',
                lineHeight: 1.2,
                textDecoration: 'none',
                paddingRight: 32,
                position: 'relative',
                display: 'block',
                '& .active': {
                  display: 'inline-block',
                  fontWeight: 'bold'
                },
                '& .total': {
                  paddingLeft: 8,
                  opacity: .3,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }
               }
             }
           },
           '& .subsubcategory': {
             paddingLeft: 14
           }
         }
      }
     },
     '& .product-search': {
        backgroundColor: theme.palette.secondary.main + '1A',
        borderRadius: 6,
        marginTop: 32,
        marginBottom: 32,
        position: 'relative',
        paddingBottom: 1,
       '& label': {
          border: 'none',
          fontFamily: theme.bodyFont,
          fontSize: 18,
          textDecoration: 'underline',
          padding: 0,
          display: 'block',
          outline: 'none',
          width: '100%',
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
          textAlign: 'left',
          padding: '24px 20px',
          fontWeight: 500,
          position: 'relative',
          textDecoration: 'none',
          [theme.breakpoints.up('md')]: {
            padding: 30,
            textDecoration: 'none',
          }
       },
       '& input[type="text"]': {
           border: '1px solid ' + theme.palette.primary.main + '4D',
           borderRadius: 28,
           padding: '20px 60px 20px 12px',
           display: 'block',
           margin: 0,
           width: 'calc(100% - 14px)',
           lineHeight: 1,
           fontSize: 12,
           color: theme.palette.primary.main,
           backgroundColor: 'transparent',
           fontFamily: theme.bodyFont,
           letterSpacing: '0.032em',
           outline: 'none',
           margin: '0 7px 20px',
           '& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder': {
               opacity: 1,
           },
           [theme.breakpoints.up('md')]: {
              width: 'calc(100% - 34px)',
              margin: ' 0 17px 30px',
           }
       },
       '& input[type="submit"]': {
           position: 'absolute',
           outline: 'none',
           bottom: 34,
           right: 17,
           width: 32,
           height: 32,
           border: 'none',
           backgroundColor: 'transparent',
           borderRadius: '50%',
           fontSize: 0,
           backgroundPosition: 'center',
           backgroundImage: 'url('+icon+')',
           backgroundSize: 'cover',
           [theme.breakpoints.up('md')]: {
              bottom: 44,
              right: 27,
           }
       }
     }
   },
   pagination: {
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main + '1A',
    position: 'relative',
    margin: '36px 0',
    bottom: 0,
    display: 'flex',
    '& li': {
      display: 'block',
        '& a': {
            fontSize: 18,
            fontWeight: 500,
            margin: '0 10px',
            borderRadius: 0,
            transition: 'opacity .3s ease-in-out',
            backgroundColor: 'transparent !important',
            border: 'none',
            outline: 'none',
            fontFamily: theme.bodyFont,
            fontSize: 18,
            color: theme.palette.primary.main,
            padding: '0 6px',
            width: 'auto',
            height: 'auto',
            display: 'block',
            textAlign: 'center',
            paddingTop: 12,
            '&:hover, &:focus': {
                opacity: '.8',
            },
            '& span': {
                display: 'none',
            },
            '&::before': {
                display: 'none',
            },
            '&.active': {
                backgroundImage: 'url('+currentPage+')',
                backgroundPosition: 'center',
                color: theme.palette.primary.contrastText,
                width: 58,
                height: 80,
                paddingTop: 37,
                marginTop: -25,
                marginBottom: -5,
                marginLeft: 0,
                marginRight: 0,
            }
        },
      }
   }
});