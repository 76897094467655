import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import SideNav from '../Components/SideNav';
import SubcategoryNav from '../Components/SubCategoryNav';
import ProductsGrid from '../Components/ProductsGrid';
import ProductsList from '../Components/ProductsList';
import Pagination from '../Components/Pagination';
import styles from './CategoryPage.styles';
import Breadcrumb from '../../Breadcrumb';
import { setPage, itemsToShow } from '../../../app/actions';

const mapStateToProps = (state) => ({
  layout: state.ShopSettings.layout,
  filters: state.ShopSettings.filter,
  itemsToShow: state.ShopSettings.itemsToShow,
  page: state.ShopSettings.page
});

const mapDispatchToProps = (dispatch) => ({
  setPage: page => dispatch(setPage(page)),
  setItemsToShow: number => dispatch(itemsToShow(number)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Category extends Component {

  constructor(props) {
    super(props);

    this.props.setPage(1);
  }

  componentDidMount() {
    if (this.props.products.length > 300) {
      this.props.setItemsToShow(48);
    }
  }

  tagFilter = product => {
    const { filters } = this.props;
    
    const tags = product.product.tags && product.product.tags.map(tag => tag.slug);

    if ((filters.kichen || filters.bathrom) && !tags) return null;

    if (filters.kitchen === true && !tags.includes('kitchen')) return false;
    if (filters.bathroom === true && !tags.includes('bathroom')) return false;

    return product;
  };

  filterProducts(products) {
    return products
    .filter(this.tagFilter)
    .sort((a, b) => {
      if (!a.product.order) return 1;
      return Number(b.product.order) - Number(a.product.order);
    });
  }

  render() {
    const { uri, slug, products, name, description,wpParent, layout, id, itemsToShow, page, subcategories, total } = this.props;

    const filteredProducts = this.filterProducts(products);

    const pagetoShow = itemsToShow === 'All' ? filteredProducts : filteredProducts
    .slice(page * Number(itemsToShow) - Number(itemsToShow), page * Number(itemsToShow));
  
    const filteredSubcategories = subcategories && subcategories.map(category => {
      return {
        ...category,
        products: category.products.filter(this.tagFilter)
      }
    })
  
    const filteredSiblings = wpParent && wpParent.node.wpChildren.nodes.map(category => {
      return {
        ...category,
        products: category.products.nodes.filter(this.tagFilter),
        wpChildren: category.wpChildren.nodes.map(category => {
          return {...category, products: category.products.nodes.filter(this.tagFilter)};
        })
      }
    });

    return <>
    <Breadcrumb
      type="category"
      ancestors={wpParent && wpParent.node}
      current={{
        title: name,
        slug: slug,
        uri: uri
      }}
    />
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3}>
        <SideNav name={name} uri={uri} id={id} description={description} parent={wpParent} subcategories={filteredSubcategories} siblings={filteredSiblings} products={filteredProducts } total={total} />
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <SubcategoryNav products={filteredProducts} total={total} />
        {layout === 'grid' && <ProductsGrid products={pagetoShow} />}
        {layout === 'list' && <ProductsList products={pagetoShow} />}
        <Pagination products={filteredProducts} />
      </Grid>
    </Grid>
  </>
  }
}

export default withStyles(styles)(connector(Category));