import React from 'react';
import ProductsGrid from './ProductsGrid';
import { makeStyles } from '@material-ui/styles';

import {CoreHeadingBlock} from '../../blocks/CoreHeadingBlock';
import styles from './PopularProducts.styles';

const useStyles = makeStyles(theme => styles(theme));

export default function PopularProducts({ products, limit }) {
  const classes = useStyles();

  // console.log(products);

  if (!products) {
    return <div>No products</div>;
  }

  let count = 0;
  
  if(!limit) {
    limit = 999;
  }

  let prodArray = [];

  products.forEach((product) => {
    if(count < limit) {
      prodArray.push(product);
      count++;
    }
  });

  return <div className={`popular-products ${classes.productRelatedProducts}`}>
    <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: "Customers Often Look For",
          level: 4,
          backgroundColor: "",
          textColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
      }}
      innerBlocks={[]} 
    />
    <ProductsGrid products={prodArray} />
  </div>;
}